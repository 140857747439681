import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { about, allInOne, barkodOxuyucu, barkodPrinter, barkodProcessor, cardReader, carousel2, dataTerminal, ellipse, hero, pulQutulari, rightUp } from '../../assets/index';
import { useTranslation } from 'react-i18next';
import Partners from './Partners/Partners'


const About = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
  <>
   <div className='max-w-containerSm md:max-w-container mx-auto pt-[124px]'>
    <p className='text-[22px] md:text-[30px] font-medium text-center mb-10'>Haqqımızda</p>
    <div className='md:flex gap-8'>
        <div className='md:w-[505px]'>
        <img className='rounded-[20px]' src={about}></img>
        </div>
        <div className='md:w-[657px] flex flex-col justify-center mt-5 md:mt-0'>
            <p className='md:mb-6 mb-4 text-[18px] md:text-[20px]'>Biz, şirkətlərə mühəndislik və İT xidmətləri sahəsində genişmiqyaslı dəstək göstəririk.</p>
            <p className='md:mb-6 mb-4 text-[14px]'>TechWay – texnologiya dünyasında yeniliklərə açıq, müştərilərimizin ehtiyaclarına uyğun həllər təqdim edən bir şirkətdir. Bizim əsas məqsədimiz, biznesləri rəqəmsal dünyada inkişaf etdirmək, onlara texnologiya ilə əlaqəli xidmətlər təqdim edərək müvəffəqiyyət qazanmalarına dəstək olmaqdır. TechWay olaraq, müasir texnologiyaların və təlim xidmətlərinin gücü ilə işinizi növbəti səviyyəyə qaldırırıq.</p>
            <p className='md:mb-4 mb-3 font-medium'>Göstərdiyimiz xidmətlər</p>
            <p className='md:mb-3 mb-2 text-[14px] flex items-center'>
  <img className='w-2 h-2 mr-2' src={ellipse} alt="Ellipse Icon" />
  Veb-sayt hazırlanması
</p>
<p className='md:mb-3 mb-2 text-[14px] flex items-center'>
  <img className='w-2 h-2 mr-2' src={ellipse} alt="Ellipse Icon" />
  Mobil Tətbiqlərin Hazırlanması
</p>
<p className='md:mb-3 mb-2 text-[14px] flex items-center'>
  <img className='w-2 h-2 mr-2' src={ellipse} alt="Ellipse Icon" />
  Təlimçi Bazası
</p>
<p className='md:mb-3 mb-2 text-[14px] flex items-center'>
  <img className='w-2 h-2 mr-2' src={ellipse} alt="Ellipse Icon" />
  SMM Xidmətlər
</p>

        </div>
    </div>
   </div>
   <Partners/>
   </>
  );
}; 
 
export default About; 