import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { project1, project2, project3, project4, project5, project6, rightArrow } from '../../../assets/index';
import { useTranslation } from 'react-i18next';

const ProjectsShort = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  const projects = [
    { id: 1, image: project1 },
    { id: 2, image: project2 },
    { id: 3, image: project3 },
    { id: 4, image: project4 },
    { id: 5, image: project5 },
    { id: 6, image: project6 },
  ];

  return (
    <div className='max-w-containerSm md:max-w-container mx-auto mt-[44px] md:mt-[80px]'>
      <p className='text-[22px] md:text-[30px] font-medium text-center mb-10'>Layihələr</p>
      <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
        {projects.map((project) => (
          <a key={project.id} href={`/projects/${project.id}`}>
            <div className="relative md:w-[400px]">
              <img className="rounded-[20px] mb-4 w-full" src={project.image} alt={`Project ${project.id}`} />
              <img
                className="absolute top-5 right-5 w-[24px] h-[24px]"
                src={rightArrow}
                alt="Sağ üst fotoğraf"
              />
            </div>
          </a>
        ))}
      </div>
      <div className='mt-5 mx-auto flex justify-center'>
        <a href='/projects'>
          <p className='text-black text-base font-normal inline-block border-b border-black'>Hamısını gör</p>
        </a>
      </div>
    </div>
  );
};

export default ProjectsShort; 
