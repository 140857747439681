import React from 'react'
import { hero} from '../../../assets/index';
import { useTranslation } from 'react-i18next';

const ServicesTelimci = () => {

  const { t, i18n } = useTranslation();
  return (
   <>
   <div className='md:max-w-container max-w-containerSm mx-auto pt-[124px]'>
   <p className='text-[22px] md:text-[30px] font-medium'>{t("Təlimçi Bazası")}</p>
   <div className="md:flex gap-3 mt-3"> 
  <a href="/services/vebsayt">
    <button className="h-[36px] text-[black] bg-[#EBEEF2] rounded-[20px] w-full text-left px-2 hover:bg-[#0023A1] hover:text-[white] transition-all duration-300 ease-in-out">
      {t("Veb-sayt hazırlanması")}
    </button>
  </a>
  <a href="/services/mobil">
    <button className="h-[36px] text-[black] bg-[#EBEEF2] rounded-[20px] w-full mt-2 md:mt-0 text-left px-2 hover:bg-[#0023A1] hover:text-[white] transition-all duration-300 ease-in-out">
      {t("Mobil Tətbiqlərin Hazırlanması")}
    </button>
  </a>
  <a href="/services/telimci">
    <button disabled className="h-[36px] text-[white] bg-[#0023A1] rounded-[20px] mt-2 md:mt-0 w-full text-left px-2">
      {t("Təlimçi Bazası")}
    </button>
  </a>
  <a href="/services/smm">
    <button className="h-[36px] text-[black] bg-[#EBEEF2] rounded-[20px] w-full mt-2 md:mt-0 text-left px-2 hover:bg-[#0023A1] hover:text-[white] transition-all duration-300 ease-in-out">
      {t("SMM Xidmətlər")}
    </button>
  </a>
</div>
   <div className='flex justify-between mt-[34px] '>
    <div>
      <img src={hero}></img>
      <p className='mt-5'>{t("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae lacinia lorem. Praesent pretium metus non metus vehicula accumsan. Nulla hendrerit efficitur orci vel varius. Vivamus elementum mauris sapien, eu porta tortor dictum ac. Fusce facilisis vel metus in accumsan. Nullam condimentum varius mauris quis efficitur. Nulla hendrerit vitae augue ac tempor. Phasellus posuere porttitor justo non aliquet. Nulla facilisi. Sed imperdiet viverra velit, sit amet condimentum ante dictum quis. Phasellus suscipit blandit nisi ac luctus. Curabitur vel dapibus nunc. Phasellus rhoncus tellus lectus, et mollis tellus fermentum a. Duis id lacus vulputate, mattis metus rutrum, pretium mi. Morbi cursus ultrices augue, sed hendrerit odio cursus nec. Donec porttitor maximus justo, eu efficitur massa varius a. In vestibulum tellus ac neque porttitor, at rhoncus mauris vehicula. Fusce quis lorem nisi. Sed semper, tellus vel varius tempus, sapien eros dictum magna, eget convallis nulla turpis quis nunc. Curabitur ultricies laoreet lectus, vel laoreet orci mattis nec. Proin vehicula justo leo, ac porta orci posuere ut. Vivamus quis eleifend lacus. Cras tempus ullamcorper turpis quis efficitur. Nullam nec hendrerit ligula.")}</p>
    </div>
   </div>
   </div>
  </>
  )
}

export default ServicesTelimci