import React from 'react'
import { hero} from '../../assets/index';
import { useTranslation } from 'react-i18next';

const BlogDetails = () => {

  const { t, i18n } = useTranslation();
  return (
   <>
   <div className='md:max-w-container max-w-containerSm mx-auto pt-[124px]'>
   <p className='text-[22px] md:text-[30px] font-medium text-center'>{t("Bloq")}</p>
   <div className='flex justify-between mt-[34px]'>
    <div>
      <img src={hero}></img>
      <p className='text-[18px] md:text-[26px] font-medium my-5'>Fusce tincidunt commodo sapien, quis prttitor.</p>
      <p >{t("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae lacinia lorem. Praesent pretium metus non metus vehicula accumsan. Nulla hendrerit efficitur orci vel varius. Vivamus elementum mauris sapien, eu porta tortor dictum ac. Fusce facilisis vel metus in accumsan. Nullam condimentum varius mauris quis efficitur. Nulla hendrerit vitae augue ac tempor. Phasellus posuere porttitor justo non aliquet. Nulla facilisi. Sed imperdiet viverra velit, sit amet condimentum ante dictum quis. Phasellus suscipit blandit nisi ac luctus. Curabitur vel dapibus nunc. Phasellus rhoncus tellus lectus, et mollis tellus fermentum a. Duis id lacus vulputate, mattis metus rutrum, pretium mi. Morbi cursus ultrices augue, sed hendrerit odio cursus nec. Donec porttitor maximus justo, eu efficitur massa varius a. In vestibulum tellus ac neque porttitor, at rhoncus mauris vehicula. Fusce quis lorem nisi. Sed semper, tellus vel varius tempus, sapien eros dictum magna, eget convallis nulla turpis quis nunc. Curabitur ultricies laoreet lectus, vel laoreet orci mattis nec. Proin vehicula justo leo, ac porta orci posuere ut. Vivamus quis eleifend lacus. Cras tempus ullamcorper turpis quis efficitur. Nullam nec hendrerit ligula.")}</p>
    </div>
   </div>
   </div>
  </>
  )
}

export default BlogDetails