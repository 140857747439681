import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown, FaChevronUp, FaTimes } from 'react-icons/fa';
import { logo, logoHeader, logoOther, messageBlue, mpost, playCircle } from "../../assets/index";
import { useTranslation, initReactI18next } from "react-i18next";
import { azflag, engflag, rusflag } from '../../assets/index';
import i18n from "i18next";
import enTranslation from "../i18n/en/translation.json";
import azTranslation from "../i18n/az/translation.json";
import rusTranslation from "../i18n/rus/translation.json";
import { NavLink } from "react-router-dom";




const languageOptions = [
  { value: "en", label: "EN", flag: engflag },
  { value: "az", label: "AZ", flag: azflag },
  { value: "rus", label: "RU", flag: rusflag },
];

i18n.use(initReactI18next).init({
  resources: {
      en: { translation: enTranslation }, 
      az: { translation: azTranslation },
      rus: { translation: rusTranslation },
  },
  lng: localStorage.getItem("language") || "az",
  fallbackLng: "az",
  interpolation: { escapeValue: false },
});


const Header = () => {
  const { t, i18n } = useTranslation();
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const productsTimeoutRef = useRef(null);
  const servicesTimeoutRef = useRef(null);

  const [showSubmenu, setShowSubmenu] = useState(false);
  const [showSubmenu2, setShowSubmenu2] = useState(false);
  const [showSubmenu3, setShowSubmenu3] = useState(false);

  


  const openProductsMenu = () => {
    if (productsTimeoutRef.current) {
      clearTimeout(productsTimeoutRef.current);
    }
    setIsProductsOpen(true);
  };

  const closeProductsMenu = () => {
    productsTimeoutRef.current = setTimeout(() => { 
      setIsProductsOpen(false); 
    }, 200);
  };

  const openServicesMenu = () => {
    if (servicesTimeoutRef.current) {
      clearTimeout(servicesTimeoutRef.current);
    }
    setIsServicesOpen(true);
  };

  const [activeMenu, setActiveMenu] = useState(null);

  const toggleMenu = (menuIndex) => {
    setActiveMenu(activeMenu === menuIndex ? null : menuIndex);
  };
 

  const closeServicesMenu = () => {
    servicesTimeoutRef.current = setTimeout(() => {
      setIsServicesOpen(false);
    }, 200);
  };
  const toggleProductsSubMenu = () => {
    setIsProductsOpen(!isProductsOpen);
  };
 
  const toggleServicesSubMenu = () => {
    setIsServicesOpen(!isServicesOpen);
  };

  const toggleHamburgerMenu = () => setIsHamburgerMenuOpen(!isHamburgerMenuOpen);

  const isActiveLink = (path) => {
    return window.location.pathname === path;
}; 


  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions.find(lang => lang.value === i18n.language));

  useEffect(() => {
      const savedLanguage = localStorage.getItem("language");
      if (savedLanguage) {
          const language = languageOptions.find(lang => lang.value === savedLanguage);
          if (language) {
              setSelectedLanguage(language);
              i18n.changeLanguage(language.value);
          }
      }
  }, []);

  const toggleDropdown = () => {
      setIsOpen(!isOpen);
  };

  const handleLanguageChange = (language) => {
      setSelectedLanguage(language);
      setIsOpen(false);
      i18n.changeLanguage(language.value);
      localStorage.setItem("language", language.value);
  };


  
 
  return (
    <>
      {/* <nav className="bg-[red] h-[74px] ">
        <div className="max-w-container mx-auto">
          <div className="mx-auto flex items-center justify-between py-3 "> 
            <div>
              <a href='/'> <img src={logoOther} alt="Logo" className="h-16" /></a>
            </div>  
            <div className="hidden space-x-2 text-[10px] xl:text-[15px] 2xl:text-base 3xl:text-[17px]  gap-10 w-[574px]  lg:flex"> 
            <NavLink 
                to="/" 
                end 
                className={({ isActive }) => isActive ? "text-[#f1b559]" : "text-black text-base font-normal"}
              >
                {t("Ana səhifə")}
              </NavLink>
              <NavLink 
                to="/services" 
                className={({ isActive }) => isActive ? "text-[#f1b559]" : "text-black text-base font-normal"}
              >
                {t("Xidmətlər")}
              </NavLink>
              <NavLink 
                to="/about" 
                className={({ isActive }) => isActive ? "text-[#f1b559]" : "text-black text-base font-normal"}
              >
                {t("Haqqımızda")}
              </NavLink>
              <NavLink 
                to="/projects" 
                className={({ isActive }) => isActive ? "text-[#f1b559]" : "text-black text-base font-normal"}
              >
                {t("Layihələrimiz")}
              </NavLink>
              <NavLink 
                to="/contact" 
                className={({ isActive }) => isActive ? "text-[#f1b559]" : "text-black text-base font-normal"}
              >
                {t("Əlaqə")}
              </NavLink>
            </div>
            <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.5 2.75C6.66751 2.75 2.75 6.66751 2.75 11.5C2.75 16.3325 6.66751 20.25 11.5 20.25C16.3325 20.25 20.25 16.3325 20.25 11.5C20.25 6.66751 16.3325 2.75 11.5 2.75ZM1.25 11.5C1.25 5.83908 5.83908 1.25 11.5 1.25C17.1609 1.25 21.75 5.83908 21.75 11.5C21.75 14.0605 20.8111 16.4017 19.2589 18.1982L22.5303 21.4697C22.8232 21.7626 22.8232 22.2374 22.5303 22.5303C22.2374 22.8232 21.7626 22.8232 21.4697 22.5303L18.1982 19.2589C16.4017 20.8111 14.0605 21.75 11.5 21.75C5.83908 21.75 1.25 17.1609 1.25 11.5Z" fill="black"/>
</svg>
            </div>

          
          </div>
        </div>
      </nav>             */}
       <nav className=" w-full z-10 h-[91px] fixed bg-[#F8F8F8] ">
        <div className="md:max-w-container max-w-containerSm mx-auto">
          <div className="mx-auto flex items-center justify-between pt-[24px]"> 
          <div>
              <a href='/'> <img src={logoHeader} alt="Logo" className="h-[50px]" /></a>
            </div> 
            <div className="hidden md:flex w-[509px] h-[28px] bg-[#F1F1F1] justify-between rounded-[24px] px-3 items-center"> 
            <NavLink 
                to="/" 
                end 
                className={({ isActive }) => isActive ? "text-[white] bg-[#2CA5FF] rounded-[20px] px-2" : "text-black text-base font-normal"}
              >
                {t("Əsas səhifə")}
              </NavLink>
              <div
        className="relative inline-block"
        onMouseEnter={() => setShowSubmenu2(true)}
        onMouseLeave={() => setShowSubmenu2(false)}
      >
        <NavLink
          to="/services"
          className={({ isActive }) =>
            isActive ? "text-[white] bg-[#2CA5FF] rounded-[20px] px-2" : "text-black text-base font-normal"
          }
        >
          {t("Xidmətlər")}
        </NavLink>

        {showSubmenu2 && (
          <div className="absolute bg-[#F1F1F1] border border-[#BDBDBD] rounded-[16px] w-[222px]">
            <NavLink
              to="/services/vebsayt"
              className="block px-2 py-2 text-black text-[14px] font-normal"
            >
              {t("Veb-sayt hazırlanması")}
            </NavLink>
            <NavLink
              to="/services/mobil"
              className="block px-2 py-2 text-black text-[14px]"
            >
              {t("Mobil Tətbiqlərin Hazırlanması")}
            </NavLink>
            <NavLink
              to="/services/telimci"
              className="block px-2 py-2 text-black text-[14px] "
            >
              {t("Təlimçi Bazası")}
            </NavLink>
            <NavLink
              to="/services/smm"
              className="block px-2 py-2 text-black text-[14px]"
            >
              {t("SMM Xidmətlər")}
            </NavLink>
          </div>
        )}
      </div>
              <NavLink 
                to="/projects" 
                className={({ isActive }) => isActive ? "text-[white] bg-[#2CA5FF] rounded-[20px] px-2" : "text-black text-base font-normal"}
              >
                {t("Layihələr")}
              </NavLink>
              {/* <div
        className="relative inline-block"
        onMouseEnter={() => setShowSubmenu(true)}
        onMouseLeave={() => setShowSubmenu(false)}
      >
        <NavLink
          to="/projects"
          className={({ isActive }) =>
            isActive ? "text-[#f1b559]" : "text-black text-base font-normal"
          }
        >
          {t("LAYİHƏLƏRİMİZ")}
        </NavLink>

        {showSubmenu && (
          <div className="absolute bg-white shadow-lg w-[202px] rounded">
            <NavLink
              to="/projects/finished"
              className="block px-4 py-2 text-black hover:bg-[#f1f1f1] text-base font-normal"
            >
              Bitmiş layihələr
            </NavLink>
            <NavLink
              to="/projects/unfinished"
              className="block px-4 py-2 text-black hover:bg-[#f1f1f1]"
            >
              Davam edən layihələr
            </NavLink>
          </div>
        )}
      </div> */}
               <div
        className="relative inline-block"
        onMouseEnter={() => setShowSubmenu3(true)}
        onMouseLeave={() => setShowSubmenu3(false)}
      >
        <NavLink
          to="/about"
          className={({ isActive }) =>
            isActive ? "text-[white] bg-[#2CA5FF] rounded-[20px] px-2" : "text-black text-base font-normal"
          }
        >
          {t("Haqqımızda")}
        </NavLink>

        {showSubmenu3 && (
          <div className="absolute bg-[#F1F1F1] border border-[#BDBDBD] rounded-[16px] w-[122px] ">
            <NavLink
              to="/team"
              className="block px-2 py-2 text-black text-[14px] font-normal"
            >
              {t("Komanda üzvləri")}
            </NavLink>
          </div>
        )}
      </div>
              <NavLink 
                to="/blogs" 
                className={({ isActive }) => isActive ? "text-[white] bg-[#2CA5FF] rounded-[20px] px-2" : "text-black text-base font-normal"}
              >
                {t("Bloq")}
              </NavLink>
              <NavLink 
                to="/contact" 
                className={({ isActive }) => isActive ? "text-[white] bg-[#2CA5FF] rounded-[20px] px-2" : "text-black text-base font-normal"}
              >
                {t("Əlaqə")}
              </NavLink>
            </div>
            <div className="flex">
              {/* <a className="hidden md:inline" href="/contact">
                <button className="bg-[#f1b559] hover:bg-[#898989] w-[146px] rounded-lg h-10 text-[white]">
                  Layihəm var
                </button>
              </a> */}
              <a className="hidden md:inline-block" href='/contact'> <button className='bg-[white] text-[black] rounded-[20px] w-[148px] h-[40px] flex items-center justify-center'>
<img className='h-6 w-6 mr-2' src={messageBlue} alt="Right Up Icon" />
Layihəm var
</button></a>
          <button className="text-[black] focus:outline-none md:hidden" onClick={toggleHamburgerMenu}>
        <i className="fa-solid fa-bars text-[black] text-[24px]"></i>
      </button>

      {isHamburgerMenuOpen && (
        <div className={`fixed inset-y-0 left-0 transform ${isHamburgerMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out bg-white w-full z-50 shadow-lg`}>

          <div className="flex flex-col items-start w-full">
          
           
          
            <div className=" items-center w-[100%] justify-center sm:justify-end h-screen bg-[#F8F8F8]">
              <div className="items-center h-[80px]  flex justify-between p-4">
              <div>
              <a href='/'> <img src={logoHeader} alt="Logo" className="h-[43px]" /></a>
            </div> 
          <button className=" text-white p-2 rounded" onClick={toggleHamburgerMenu}>
          <i className="fa-solid fa-xmark text-[black] text-[24px]"></i>
          </button>
              </div>
            <div className="items-center gap-4 h-[300px] p-4"> 

                    {/* Button 3 */}
      <div className="w-full relative ">
        <a href="/">
        <button
          onClick={() => toggleMenu('menu1')}
          className="w-full h-[50px] text-[black] rounded-lg text-left"
        >
          {t("Əsas səhifə")}
        </button>
        </a>
      </div>

      {/* Button 1 */} 
      <div className="w-full relative z-50"> {/* Menüye dış z-index ekledik */}
      <button
  onClick={() => toggleMenu('menu2')}
  className="w-full h-[50px] text-[black] rounded-lg flex items-center justify-between"
>
  <a href="/services"><span>{t("Xidmətlər")}</span></a>
  <i className="fas fa-chevron-down text-black"></i>
</button>

  {activeMenu === 'menu2' && (
    <div className="absolute left-0 right-0 mt-2 space-y-2 bg-gray-100 rounded shadow-lg z-50 "> {/* Menüye iç z-index ekledik */}
     <a 
  href="/services/vebsayt" 
  className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out"
>
  {t("Veb-sayt hazırlanması")}
</a>

      <a href="/services/mobil" className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out">
        {t("Mobil Tətbiqlərin Hazırlanması")}
      </a>
      <a href="/services/telimci" className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out">
        {t("Təlimçi Bazası")}
      </a>
      <a href="/services/smm" className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out">
        {t("SMM Xidmətlər")}
      </a>
    </div>
  )}
</div>

                    {/* Button 3 */}
                    <div className="w-full relative ">
        <a href="/projects">
        <button
          onClick={() => toggleMenu('menu3')}
          className="w-full h-[50px] text-[black] rounded-lg text-left"
        >
          {t("Layihələr")}
        </button>
        </a>
      </div>
      
                    {/* Button 3 */}
                    <div className="w-full relative z-40"> {/* Menüye dış z-index ekledik */}
      <button
  onClick={() => toggleMenu('menu3')}
  className="w-full h-[50px] text-[black] rounded-lg flex items-center justify-between"
>
  <a href="/about"><span>{t("Haqqımızda")}</span></a>
  <i className="fas fa-chevron-down text-black"></i>
</button>

  {activeMenu === 'menu3' && (
    <div className="absolute left-0 right-0 mt-2 space-y-2 bg-gray-100 rounded shadow-lg z-50 "> {/* Menüye iç z-index ekledik */}
     <a 
  href="/team" 
  className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out"
>
  {t("Komanda üzvləri")}
</a>

    </div>
  )}
</div>
      
                    {/* Button 3 */}
                    <div className="w-full relative">
        <a href="/blogs">
        <button
          onClick={() => toggleMenu('menu5')}
          className="w-full h-[50px] text-[black] rounded-lg text-left"
        >
          {t("Bloq")}
        </button>
        </a>
      </div>
      
                    {/* Button 3 */}
                    <div className="w-full relative mb-5">
        <a href="/contact">
        <button
          onClick={() => toggleMenu('menu6')}
          className="w-full h-[50px] text-[black] rounded-lg text-left"
        >
          {t("Əlaqə")}
        </button>
        </a>
      </div>


    </div>
            <div className="p-4">
           <a href="/contact"><p className="text-[#2C5AFF]"> Layihəm var</p></a>
            </div>

          </div>
          </div>
        </div>
      )}   
            </div>
          </div>
        </div>
      </nav>   
    </>
  ); 
};

export default Header;
