import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { blogs1, blogs2, blogs3 } from '../../../assets/index';
import { useTranslation } from 'react-i18next';

const Blogs = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  const blogPosts = [
    { id: 1, image: blogs1, category: 'Analitika', description: 'Fusce tincidunt commodo sapien, quis prttitor.' },
    { id: 2, image: blogs2, category: 'Security', description: 'Fusce tincidunt commodo sapien, quis prttitor.' },
    { id: 3, image: blogs3, category: 'Management', description: 'Fusce tincidunt commodo sapien, quis prttitor.' },
  ];

  return (
    <div className='max-w-containerSm md:max-w-container mx-auto mt-[44px] md:mt-[80px]'>
      <p className='text-[22px] md:text-[30px] font-medium text-center mb-10'>Bloq</p>
      <div className='grid grid-cols-1 md:grid-cols-3 gap-6'>
        {blogPosts.map((post) => (
          <a key={post.id} href={`/blogs/${post.id}`}>
            <div className='bg-[white] rounded-[30px] border-[1px] border-[#9C9C9C]'>
              <div className='m-3'>
                <img className='rounded-[20px] mb-4 w-full md:w-[373px]' src={post.image} alt={post.category} />
                <span className='bg-[#0023A1] text-[white] px-2 rounded-[20px] mb-2 text-[14px]'>{post.category}</span>
                <p>{post.description}</p>
              </div>
            </div>
          </a>
        ))}
      </div>
      <div className='mt-5 mx-auto flex justify-center'>
        <a href='/blogs'>
          <p className='text-black text-base font-normal inline-block border-b border-black'>Hamısını gör</p>
        </a>
      </div>
    </div>
  );
};

export default Blogs;
