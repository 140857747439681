import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { team1, team2, team3, team4 } from '../../assets/index';
import { useTranslation } from 'react-i18next';

const Team = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  const teamMembers = [
    { id: 1, image: team1, name: 'Donald L.Juarez', role: 'İT specialist' },
    { id: 2, image: team2, name: 'Donald L.Juarez', role: 'İT specialist' },
    { id: 3, image: team3, name: 'Donald L.Juarez', role: 'İT specialist' },
    { id: 4, image: team4, name: 'Donald L.Juarez', role: 'İT specialist' },
  ];

  return (
    <div className='max-w-containerSm md:max-w-container mx-auto mt-[44px] md:mt-[80px]'>
      <p className='text-[22px] md:text-[30px] font-medium text-center mb-10'>Digər komanda üzvləri</p>
      <div className='grid grid-cols-1 md:grid-cols-4 gap-6'>
        {teamMembers.map((member) => (
          <a key={member.id} href={`/team/${member.id}`}>
            <div className='rounded-[30px] border-[1px] border-[#9C9C9C]'>
              <div className='m-3'>
                <img className='rounded-[20px] mb-5 w-full md:w-[271px]' src={member.image} alt={member.name} />
                <p className='font-medium'>{member.name}</p>
                <p className='text-[14px] text-[#2C5AFF]'>{member.role}</p>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Team;
