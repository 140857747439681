import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { about, allInOne, barkodOxuyucu, barkodPrinter, barkodProcessor, cardReader, carousel2, dataTerminal, ellipse, hero, pulQutulari, rightUp, team1 } from '../../assets/index';
import { useTranslation } from 'react-i18next';
import OtherTeam from './Other'


const About = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
  <>
   <div className='max-w-containerSm md:max-w-container mx-auto pt-[124px]'>
    <div className='md:flex gap-8'>
        <div className='md:w-[505px]'>
        <img className='rounded-[20px]' src={team1}></img>
        </div>
        <div className='md:w-[657px] flex flex-col justify-center mt-5 md:mt-0'>
        <p className='text-[22px] md:text-[30px] font-medium'>Donald L.Juarez</p>
        <p className='text-[18px] text-[#2C5AFF] mb-5'>İT specialist</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae lacinia lorem. Praesent pretium metus non metus vehicula accumsan. Nulla hendrerit efficitur orci vel varius. Vivamus elementum mauris sapien, eu porta tortor dictum ac. Fusce facilisis vel metus in accumsan. Nullam condimentum varius mauris quis efficitur. Nulla hendrerit vitae augue ac tempor. Phasellus posuere porttitor justo non aliquet. Nulla facilisi. Sed imperdiet viverra velit, sit amet condimentum ante dictum quis. Phasellus suscipit blandit nisi ac luctus. Curabitur vel dapibus nunc. Phasellus rhoncus tellus lectus, et mollis tellus fermentum a. Duis id lacus vulputate, mattis metus rutrum, pretium mi.</p>

        </div>
    </div>
   </div>
   <OtherTeam/>
   </>
  );
}; 
 
export default About; 