import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination'; // Sayfalandırma stillerini yüklemek için
import { Pagination, Scrollbar } from 'swiper/modules';
import './partners.css'

import { partners1,partners2, partners3, partners4, partners5, partners6, partners7, partners8, partners9, partners10 } from '../../../assets/index';

const Partners = () => {
  return (
      <div className='max-w-containerSm md:max-w-container mx-auto mt-[44px] md:mt-[80px]'>
         <p className='text-[22px] md:text-[30px] font-medium text-center mb-10'>Partnyorlar</p>
        <div className='md:flex justify-between'>
        <div className='flex justify-center items-center'>
          <img className='h-[79px]' src={partners1}></img>
        </div>
        <div className='flex justify-center items-center mt-4 md:mt-0'>
          <img className='h-[117px]' src={partners2}></img>
        </div>
        <div className='flex justify-center items-center mt-4 md:mt-0'>
          <img className='h-[147px]' src={partners3}></img>
        </div>
        <div className='flex justify-center items-center mt-4 md:mt-0'>
          <img className='h-[105px]' src={partners4}></img>
        </div>
        </div>
      </div>
  );
};

export default Partners;