import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { t } from "i18next";
import './Carousel.css'
import { useTranslation } from "react-i18next"; 
import { hero, playCircle, rightUp } from "../../../assets/index";
 
  const Carousel = () => {

  const { t, i18n } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [showText, setShowText] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const slides = [
    { img: hero, text: "Texnologiyanın Gücü İlə Gələcəyə Bir Addım Daha Yaxın Olun", content: "Biznesinizi rəqəmsal aləmdə inkişaf etdirərək TechWay-in qabaqcıl texnologiya həlləri ilə uğura çatdırın." },
    // { img: hero, text: "TANURA ilə Hər Gün Təravətli Dəri", content: "Həkim-kosmetoloq və kosmetoloqlar üçün nəzərdə tutulmuş məhsullar" },
    // { img: hero, text: "TANURA ilə Hər Gün Təravətli Dəri", content: "Həkim-kosmetoloq və kosmetoloqlar üçün nəzərdə tutulmuş məhsullar" },
  ];

  useEffect(() => {
    const textTimer = setTimeout(() => {
      setShowText(true);
    }, 500);

    const buttonTimer = setTimeout(() => {
      setShowButton(true);
    }, 1500);

    return () => {
      clearTimeout(textTimer);
      clearTimeout(buttonTimer);
    };
  }, [activeIndex]);

  return (
    <div className="md:max-w-container mx-auto ">
      {/* Header is placed outside the Swiper for it to stay on top */}

      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        onSlideChange={(swiper) => {
          setShowText(false);
          setShowButton(false);
          setActiveIndex(swiper.activeIndex);
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      > 
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="relative">
              <img src={slide.img} alt={`Slide ${index + 1}`} className="w-full h-[429px] md:h-[598px] object-cover rounded-[16px] mt-32" />
              {activeIndex === index && (
                <div className="absolute inset-0 flex flex-col justify-center items-center lg:items-start text-white p-4 lg:px-7 py-5 transition-opacity duration-1000">
                  <div className="md:w-[1200px] relative mx-auto  md:text-center lg:text-left ">
                    <div className="md:h-[30px] md:flex ">
                      <a href="/services/vebsayt"><button className="h-[30px] rounded-[20px] px-[10px] text-white bg-[#FFD076] mr-2 flex justify-center items-center">Veb-sayt hazırlanması</button></a>
                      <a href="/services/mobil"><button className="h-[30px] rounded-[20px] px-[10px] text-white bg-[#ABDEFB] mr-2 flex justify-center items-center mt-[4px] md:mt-0">Mobil Tətbiqlərin Hazırlanması</button></a>
                      <a href="/services/telimci"><button className="h-[30px] rounded-[20px] px-[10px] text-white bg-[#13C846] mr-2 flex justify-center items-center mt-[4px] md:mt-0">Təlimçi Bazası</button></a>
                      <a href="/services/smm"><button className="h-[30px] rounded-[20px] px-[10px] text-black bg-[#FFFCAE] mr-2 flex justify-center items-center mt-[4px] md:mt-0">SMM Xidmətlər</button></a>
                        </div>
                    <h2 className={`text-white text-[18px] md:text-[32px] font-semibold w-[80%] md:w-[60%] mb-4 md:mb-6 md:mt-[250px] mt-[32px] lg:mb-6 transition-all duration-1000 transform ${showText ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'}`}>
                      {t(slide.text)}
                    </h2>
                    <h2 className={` text-white text-[14px] md:text-[20px] font-normal w-[80%] md:w-[60%] mb-5 lg:mb-5 transition-all duration-1000 transform ${showText ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'}`}>
                      {t(slide.content)}
                    </h2>

                    <div className="flex justify-between">
                    <a href='/about'> <button className='bg-[#2C5AFF] text-[white] rounded-[20px] w-[148px] h-[40px] flex items-center justify-center'>
  Daha ətraflı 
  <img className='h-6 w-6 ml-2' src={rightUp} alt="Right Up Icon" />
</button></a>
<a href='/about'> <button className='bg-[white] text-[black] rounded-[20px] w-[148px] h-[40px] flex items-center justify-center'>
<img className='h-6 w-6 mr-2' src={playCircle} alt="Right Up Icon" />
Videonu izlə
</button></a>
                    </div>
              
                  </div> 
                </div>
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Carousel;
