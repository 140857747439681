import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { about, allInOne, barkodOxuyucu, barkodPrinter, barkodProcessor, cardReader, carousel2, dataTerminal, hero, pulQutulari, blogs1, blogs2, blogs3, project1, project2, project3, project4, project5, project6, rightArrow } from '../../assets/index';
import { useTranslation } from 'react-i18next';


const ServicesShort = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
   <div className='max-w-containerSm md:max-w-container mx-auto pt-[124px]'>
    <p className='text-[22px] md:text-[30px] font-medium text-center mb-10'>Xidmətlər</p>
    <div className='md:flex gap-6'>
   <a href='/services/vebsayt'>
   <div className='md:w-[292px] h-[312px] md:h-[390px] bg-[#FFD076] rounded-[20px] px-[22px] pt-[50px] '>
   <div className='flex justify-between'>
   <p className='text-[18px] md:text-[20px] font-medium text-white'>Veb-sayt hazırlanması</p>
   <img className='w-6 h-6' src={rightArrow}></img>
   </div>
   <p className='md:mt-[62px] mt-[32px] text-base text-[#9A9A9A]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec fermentum mollis lorem id vehicula. Pellentesque eget ex pharetra, fermentum tellus vestibulum, pretium risus. Morbi et dignissim nisl.</p>
   </div>
   </a>
   <a href='/services/mobil'>
   <div className='md:w-[292px] h-[312px] md:h-[390px] bg-[#ABDEFB] rounded-[20px] px-[22px] pt-[50px] mt-[9px] md:mt-0'>
   <div className='flex justify-between'>
   <p className='text-[18px] md:text-[20px] font-medium text-black'>Mobil tətbiqlərin hazırlanması</p>
   <img className='w-6 h-6' src={rightArrow}></img>
   </div>
   <p className='mt-[32px] text-base text-[#9A9A9A]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec fermentum mollis lorem id vehicula. Pellentesque eget ex pharetra, fermentum tellus vestibulum, pretium risus. Morbi et dignissim nisl.</p>
   </div>
   </a>
   <a href='/services/telimci'>
   <div className='md:w-[292px] h-[312px] md:h-[390px] bg-[#13C846] rounded-[20px] px-[22px] pt-[50px] mt-[9px] md:mt-0'>
   <div className='flex justify-between'>
   <p className='text-[18px] md:text-[20px] font-medium text-white'>Təlimçi bazası</p>
   <img className='w-6 h-6' src={rightArrow}></img>
   </div>
   <p className='md:mt-[62px] mt-[32px] text-base text-[#F1F1F1]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec fermentum mollis lorem id vehicula. Pellentesque eget ex pharetra, fermentum tellus vestibulum, pretium risus. Morbi et dignissim nisl.</p>
   </div>
   </a>
  <a href='/services/smm'>
  <div className='md:w-[292px] h-[312px] md:h-[390px] bg-[#FFFCAE] rounded-[20px] px-[22px] pt-[50px] mt-[9px] md:mt-0'>
   <div className='flex justify-between'>
   <p className='text-[18px] md:text-[20px] font-medium text-black'>SMM Xidmətlər</p>
   <img className='w-6 h-6' src={rightArrow}></img>
   </div>
   <p className='md:mt-[62px] mt-[32px] text-base text-[#9A9A9A]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec fermentum mollis lorem id vehicula. Pellentesque eget ex pharetra, fermentum tellus vestibulum, pretium risus. Morbi et dignissim nisl.</p>
   </div>
  </a>
    
   
    </div>
   </div> 
  );
};
 
export default ServicesShort; 